<template>
  <div class="site-wrap site-wrap-home">
    <Header
      class="home-main center-box"
      :navBarFixed="navBarFixed"
      :class="{ 'text-color': navBarFixed }"
    ></Header>
    <div class="main-wrap home-wrap" ref="scrollview" @scroll="watchScroll">
      <div class="banner">
        <p class="home-main slogan text-c">千里目，全域商业数据平台</p>
        <ul class="home-main home-search-content">
          <li
            v-for="(item, index) in menus"
            :key="'menu_' + index"
            :class="{ cur: item.cate === curCate }"
            @click="changeCate(item)"
            class="cate-menu cur-pointer"
            style="display: inline-block"
          >
            {{ item.alias || item.name }}
          </li>
        </ul>
        <SearchPanel
          fromClass="home-wrap"
          :cate="curCate"
          :isHome="true"
          :cityCode="cityCode"
          :placeholder="placeholder"
          @search="search"
          @searchAll="searchAll"
        ></SearchPanel>
        <div class="home-main home-wrap home-search-content incheck">
          <span class="label text-c">大家在查</span>｜
          <span
            class="hot-item"
            v-for="(item, index) in hotWords"
            :key="'hot_' + index"
          >
            {{ item }}
          </span>
        </div>
      </div>
      <div
        class="home-data-capacity display-flex home-main align-items-center"
      >
        <div
          class="data-capacity-item display-flex justify-content-center align-items-center"
          v-for="(item, index) in dataCapacity"
          :key="'data_' + index"
        >
          <img :src="item.icon" class="item-icon" alt="" />
          <div
            class="item-content display-flex flex-direction-column justify-content-space-between"
          >
            <span class="item-name"> {{ item.name }}</span>
            <span class="item-num">
              {{ item.num }}
              <span class="item-unit">{{ item.unit }}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="business-wrap">
        <div class="home-main">
          <div class="title-wrap text-c">
            <div class="primary-title">
              <p class="en">BUSINESS EMPOWERMENT</p>
              <span class="zh center-box">商业赋能</span>
            </div>
            <p class="desc">
              以更多选择满足业务的全面需求，为业务带来多维提升与创新突破
            </p>
          </div>
          <div class="business-content">
            <!-- 商业评估 -->
            <div
              class="business-item justify-content-space-between align-items-center"
              :class="sceneShow1 ? 'height-max' : 'display-flex'"
            >
              <div
                class="business-desc"
                :class="sceneShow1 ? 'business-fadeOut' : 'business-fadeIn'"
              >
                <div class="title">商业营销评估</div>
                <div class="desc">
                  结合时空大数据、知识图谱、用户画像、深度学习等方面的能力，为客户提供精准营销解决方案，帮助客户构建多维客户洞察、精准位置投放、OMO全场景效果评估、精益化运营。
                </div>
              </div>
              <div
                class="business-img"
                :class="sceneShow1 ? 'scene-show1' : ''"
              >
                <!-- <img
                  class=""
                  :class="sceneShow1 ? 'scene-show' : ''"
                  src="../assets/images/home-business.png"
                  alt=""
                /> -->
                <img
                  class=""
                  :class="sceneShow1 ? 'scene-show' : ''"
                  src="../assets/images/home-business2.png"
                  alt=""
                />
              </div>
              <div
                v-show="!sceneShow1"
                class="play-btn cur-pointer"
                @click="playShow1()"
              >
                <span class="play-icon"></span>
                播放
              </div>
              <div
                v-show="sceneShow1"
                class="back-btn cur-pointer"
                @click="playShow1()"
              >
                <span class="back-icon"></span>
                返回
              </div>
            </div>
            <!-- 客流画像 -->
            <div
              class="business-item justify-content-space-between align-items-center"
              :class="sceneShow3 ? 'height-max' : 'display-flex'"
            >
              <div
                class="business-img-right"
                :class="sceneShow3 ? 'scene-show2' : ''"
              >
                <img
                  class=""
                  :class="sceneShow3 ? 'scene-show' : ''"
                  src="../assets/images/home-portrait.png"
                  alt=""
                />
              </div>

              <div
                class="business-desc business-desc-right"
                :class="
                  sceneShow3
                    ? 'business-fadeOut-right'
                    : 'business-fadeIn-right'
                "
              >
                <div class="title">客流画像分析</div>
                <div class="desc">
                  通过一站式客户调研及时空洞察系统，结合海量城市人口用户画像数据，帮助客户用最少的投入全面便捷分析来客并洞察周边潜在客户、合理利用广告资源实现精准投放触达目标客户。
                </div>
              </div>

              <div
                v-show="!sceneShow3"
                class="play-btn play-btn2 cur-pointer"
                @click="playShow3()"
              >
                <span class="play-icon"></span>
                播放
              </div>

              <div
                v-show="sceneShow3"
                class="back-btn cur-pointer"
                @click="playShow3()"
              >
                <span class="back-icon"></span>
                返回
              </div>
            </div>
            <!-- 地理画像 -->
            <div
              class="business-item justify-content-space-between align-items-center"
              :class="sceneShow2 ? 'height-max' : 'display-flex'"
            >
              <div
                class="business-desc"
                :class="sceneShow2 ? 'business-fadeOut' : 'business-fadeIn'"
              >
                <div class="title">地理位置分析</div>
                <div class="desc">
                  通过海量的城市商业地理数据结合可视化地图、时空智能、用户画像技术，帮助评估开店选址、推荐最优位置、合理划分区域、管理终端网点、订单智能分拣。
                </div>
              </div>
              <div
                class="business-img localtion-img"
                :class="sceneShow2 ? 'scene-show1' : ''"
              >
                <img
                  class="map-bg"
                  src="../assets/images/home-map-bg.png"
                  alt=""
                />

                <img
                  class="localtion"
                  :class="sceneShow2 ? 'scene-show' : ''"
                  src="../assets/images/home-map-left.png"
                  alt=""
                />
              </div>
              <div
                v-show="!sceneShow2"
                class="play-btn cur-pointer"
                @click="playShow2()"
              >
                <span class="play-icon"></span>
                播放
              </div>
              <div
                v-show="sceneShow2"
                class="back-btn cur-pointer"
                @click="playShow2()"
              >
                <span class="back-icon"></span>
                返回
              </div>
            </div>
            <!-- 智慧城市 -->
            <div
              class="business-item justify-content-space-between align-items-center"
              :class="sceneShow4 ? 'height-max' : 'display-flex'"
            >
              <div
                class="business-img-right"
                :class="sceneShow4 ? 'scene-show2' : ''"
              >
                <img
                  class=""
                  :class="sceneShow4 ? 'scene-show' : ''"
                  src="../assets/images/home-city.png"
                  alt=""
                />
              </div>
              <div
                class="business-desc business-desc-right"
                :class="
                  sceneShow4
                    ? 'business-fadeOut-right'
                    : 'business-fadeIn-right'
                "
              >
                <div class="title">智慧城市建设</div>
                <div class="desc">
                  利用多源融合LBS及大数据技术将人口、商业、地理信息融汇到统一的城市时空大数据平台中，实现产业洞察、精准招商、投拓决策、区域分析、智能规划、智慧综治等功能。
                </div>
              </div>

              <div
                v-show="!sceneShow4"
                class="play-btn play-btn2 cur-pointer"
                @click="playShow4()"
              >
                <span class="play-icon"></span>
                播放
              </div>
              <div
                v-show="sceneShow4"
                class="back-btn cur-pointer"
                @click="playShow4()"
              >
                <span class="back-icon"></span>
                返回
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="home-main rolling-content">
        <div class="title-wrap">
          <div class="primary-title text-c">
            <p class="en">SERVING CUSTOMERS</p>
            <span class="zh center-box">服务客户</span>
          </div>

          <p class="desc text-c">众多行业，数百家企业与政府的共同选择</p>
        </div>
        <div class="rolling-wrap">
          <img class="mask1" src="../assets/images/mask1.png" alt="" />
          <div class="home-main rolling-img">
            <img class="rolling" src="../assets/images/customer.png" alt="" />
            <img
              class="rolling rolling2"
              src="../assets/images/customer.png"
              alt=""
            />
            <!-- <img
              class="rolling rolling2"
              src="../assets/images/customer.png"
              alt=""
            />  -->
          </div>
          <img class="mask2" src="../assets/images/mask2.png" alt="" />
        </div>
      </div>
      <div class="more-ability-wrap">
        <div class="home-main">
          <div class="title-wrap">
            <div class="primary-title text-c">
              <p class="en">MORE CAPABILITIES</p>
              <span class="zh center-box">更多能力</span>
            </div>

            <p class="desc text-c">
              整合线下数据+线上广告投放数据，将线下实体门店和地理位置洞察与线上商业化营销全域数据相融合
            </p>
          </div>
          <div class="more-ability display-flex flex-wrap-wrap">
            <div
              class="more-ability-item display-flex justify-content-center"
              v-for="(item, index) in moreAbility"
              :key="'more_' + index"
            >
              <img :src="item.icon" alt="" />
              <div class="item-content display-flex flex-direction-column">
                <span class="item-name">{{ item.name }}</span>
                <span class="item-desc">{{ item.desc }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="guide-wrap">
        <div class="home-main">
          <p class="text">
            <span>探索商业数据</span> <span>发现商业机会</span>
          </p>
          <span
            @click="toBrand"
            class="btn cur-pointer display-flex justify-content-center align-items-center text-c"
            >开始免费使用
            <img src="/images/home/right.png" alt="" />
          </span>
        </div>
      </div>
      <Footer></Footer>
    </div>
    <div class="contact-panel middle-box">
      <Tooltip placement="left" theme="light" class="contact-item phone">
        <div class="icon"></div>
        <br />
        咨询热线
        <template #content>
          <p class="tooltip-phone"><span>咨询热线：</span>010-86463634</p>
        </template>
      </Tooltip>
      <Tooltip
        placement="left-start"
        theme="light"
        class="qrcode-tooltip-wrap contact-item qrcode"
      >
        <div class="icon"></div>
        <br />
        关注我们
        <template #content>
          <div class="tooltip-qrcode">
            <div>
              <div class="qrcode-icon">
                <img
                  class="center-middle-box"
                  src="@/assets/images/contact/gzh.png"
                  alt=""
                />
              </div>
              「 公众号 」
            </div>
            <div>
              <div class="qrcode-icon">
                <img
                  class="center-middle-box"
                  src="/images/home/qrcode.png"
                  alt=""
                />
              </div>
              「 小程序 」
            </div>
          </div>
        </template>
      </Tooltip>
      <div class="contact-item to-top" v-show="toTopShow" @click="toTop">
        <div class="icon"></div>
        <br />
        返回顶部
      </div>
    </div>
  </div>
</template>
<script>
import SearchPanel from "@/components/SearchPanel.vue";
import Footer from "@/components/Footer.vue";
import { openNewWindow } from "../assets/javascript/utils";
import { GetCityCode } from "@/service";
export default {
  props: [],
  data() {
    return {
      curCate: "all",
      hotWords: [],
      dataCapacity: [
        {
          icon: "/images/home/data1.png",
          name: "覆盖用户",
          num: "9.5",
          unit: "亿+",
        },
        {
          icon: "/images/home/data2.png",
          name: "地理画像",
          num: "1.5",
          unit: "亿+",
        },
        {
          icon: "/images/home/data3.png",
          name: "品牌门店",
          num: "800",
          unit: "万+",
        },
        {
          icon: "/images/home/data5.png",
          name: "品牌",
          num: "15",
          unit: "万+",
        },
        {
          icon: "/images/home/data4.png",
          name: "城市",
          num: "367",
          unit: "",
        },
      ],
      curCateHotWord: {},
      industry: [
        {
          name: "零售行业",
          content: {
            icon: "",
            title: "零售行业解决方案",
            desc: "在全渠道时代，以多源数据驱动的精细化运营成为行业趋势，其中时空维度的业务能力尤为重要，千里目融合了时空大数据、海量用户画像，提供包括区域管理、网点管理、人员调度、选址决策、业务分析、外勤管理、供应链优化等服务。",
          },
        },
        {
          name: "汽车行业",
          content: {
            icon: "",
            title: "汽车行业解决方案",
            desc: "基于行业领先的位置数据及强大AI技术能力，对新能源汽车用户停驻行为画像和各区域充电行为画像全面解析，为车企提供强大的数据服务支撑，并为车主带来优质智能出行体验。",
          },
        },
        {
          name: "房产行业",
          content: {
            icon: "",
            title: "房产行业解决方案",
            desc: "无论是商业地产、住宅地产、物业管理还是房屋中介，时空智能都是重要的生产力。长期以来，我们一直为地产行业的各类客户提供产品和服务，帮助客户在地块分析、投拓决策、客户研究、营销决策、资源管理、商机拓展等多个业务领域构建数据驱动的业务新模式。",
          },
        },
        {
          name: "金融行业",
          content: {
            icon: "",
            title: "金融行业解决方案",
            desc: "作为服务和风险防范至上的银行、保险行业，需要通过时空智能方案不断优化服务能力并及时发现相关风险，千里目提供更加合理的网点布局、更加高效的出险服务及通过数据的智能及时性来为客户不断提升竞争优势。",
          },
        },
        {
          name: "广告传媒",
          content: {
            icon: "",
            title: "广告传媒行业解决方案",
            desc: "作为服务和风险防范至上的银行、保险行业，需要通过时空智能方案不断优化服务能力并及时发现相关风险，千里目提供更加合理的网点布局、更加高效的出险服务及通过数据的智能及时性来为客户不断提升竞争优势。",
          },
        },
      ],
      industryCont: {},
      moreAbility: [
        {
          icon: "/images/home/ability1.png",
          name: "品牌分析",
          desc: "门店分布、销量分析、客流分析、到访分析、聚客分析、客户画像、线上营销分析、深度分析",
        },
        {
          icon: "/images/home/ability2.png",
          name: "位置洞察",
          desc: "自定义区域、资源配套、人口分布、客群特征、业态分布",
        },
        {
          icon: "/images/home/ability3.png",
          name: "商圈洞察",
          desc: "商业项目、商圈品牌、客流分析、区位分析、用户到访行为分析、周边客群分析",
        },
        {
          icon: "/images/home/ability4.png",
          name: "商场洞察",
          desc: "周边业态商场楼层客流分析、客流来源分析、用户进场停留行为分析、商户分布",
        },
        {
          icon: "/images/home/ability5.png",
          name: "城市洞察",
          desc: "综合经济分析、商业布局分析、交通出行分析、城市人口分析",
        },
        {
          icon: "/images/home/ability6.png",
          name: "用户画像",
          desc: "品牌偏好、常访消费场所、性别分布、年龄分布、婚姻状况、子女情况、固定资产",
        },
        {
          icon: "/images/home/ability7.png",
          name: "创意归因",
          desc: "投放媒体偏好、广告投放生命周期、创意视频详情、标签细分归因",
        },
        {
          icon: "/images/home/ability8.png",
          name: "深度分析",
          desc: "各品牌和行业多维整合线下线上定制化深度分析报告",
        },
      ],
      navBarFixed: false,
      scrollview: null,
      pageJump: {
        1: {
          page: "/location",
          detail: "/location",
        },
        2: {
          page: "/brand",
          detail: "/brandDetail",
        },
        3: {
          page: "/mall",
          detail: "/mallDetail",
        },
        4: {
          page: "/business",
          detail: "/businessDetail",
        },
        5: {
          page: "/city",
          detail: "/cityDetail",
        },
      },
      toTopShow: false,
      sceneShow1: false,
      sceneShow2: false,
      sceneShow3: false,
      sceneShow4: false,
      placeholder: "",
      cityCode: "",
    };
  },
  metaInfo: {
    meta: [
      {
        name: "keywords",
        content:
          "千里目,大数据,商业数据,数据查询,数据分析,数据可视化,用户画像,实时客流,客流统计,品牌监测,品牌销量,业态分布,周边配套,广告创意投放,人口热力图分布,商户分析,商业营销,全域数据,全国数据,时空大数据,产业洞察,门店洞察,开店选址,开关店数据,竞争对手分析,对比分析,深度分析,分析报告,数据上图,品牌排行,智能查询,商业决策,数字化",
      },
      {
        name: "description",
        content:
          "千里目全域商业数据平台，提供查品牌、查门店、查位置、查商圈、查商场、查城市、查客流、查周边、查创意等全域多维数据的查询分析服务，以数据驱动商业发展和决策，助力企业发现商业机会。",
      },
    ],
  },
  computed: {
    menus() {
      let menus = this.$store.state.menus;
      return menus.filter((menu) => menu.homeShow);
    },
  },
  watch: {},
  methods: {
    playShow1() {
      this.sceneShow1 = !this.sceneShow1;
    },
    playShow2() {
      this.sceneShow2 = !this.sceneShow2;
    },
    playShow3() {
      this.sceneShow3 = !this.sceneShow3;
    },
    playShow4() {
      this.sceneShow4 = !this.sceneShow4;
    },

    changeCate(item) {
      this.curCate = item.cate;
      this.hotWords = item.hotWords;
      this.placeholder = item.placeholder;
    },
    changeIndus(item) {
      this.industryCont = item.content;
    },
    watchScroll() {
      let scrollTop = this.$refs.scrollview.scrollTop;
      if (scrollTop > 80) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
      this.toTopShow = scrollTop > document.documentElement.clientHeight;
    },
    // 返回顶部
    toTop() {
      this.$refs.scrollview.scrollTop = 0;
    },
    // 搜索进不同的品牌页面
    search(key) {
      if (this.curCate !== "all") {
        this.$router.push(`/${this.curCate}?key=${key || ""}`);
        return;
      }
      let page = this.pageJump[key.matchingSort].page;
      this.$router.push(`${page}?key=${key.matchingWord || ""}`);
    },
    // 搜索全部没有内容
    searchAll(key) {
      this.$router.push(`/brand?key=${key || ""}`);
    },
    toBrand() {
      this.$router.push("/brand");
    },
    async getCityCode() {
      let res = await GetCityCode();
      if (res && res.code) {
        this.cityCode = res.data || "";
        localStorage.setItem("cityCode", res.data);
      }
    },
  },
  mounted() {
    this.getCityCode();
    this.hotWords = this.$store.state.menus[0].hotWords;
    this.placeholder = this.$store.state.menus[0].placeholder;
    this.industryCont = this.industry[0].content;
    this.scrollview = this.$refs.scrollview;
    if (this.scrollview) {
      this.scrollview.addEventListener("scroll", this.watchScroll, true);
    }
  },
  components: {
    SearchPanel,
    Footer,
  },
  beforeDestroy() {},
};
</script>
<style lang="less">
@import "../assets/stylesheets/home.less";
</style>
