var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "site-wrap site-wrap-home" },
    [
      _c("Header", {
        staticClass: "home-main center-box",
        class: { "text-color": _vm.navBarFixed },
        attrs: { navBarFixed: _vm.navBarFixed },
      }),
      _c(
        "div",
        {
          ref: "scrollview",
          staticClass: "main-wrap home-wrap",
          on: { scroll: _vm.watchScroll },
        },
        [
          _c(
            "div",
            { staticClass: "banner" },
            [
              _c("p", { staticClass: "home-main slogan text-c" }, [
                _vm._v("千里目，全域商业数据平台"),
              ]),
              _c(
                "ul",
                { staticClass: "home-main home-search-content" },
                _vm._l(_vm.menus, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: "menu_" + index,
                      staticClass: "cate-menu cur-pointer",
                      class: { cur: item.cate === _vm.curCate },
                      staticStyle: { display: "inline-block" },
                      on: {
                        click: function ($event) {
                          return _vm.changeCate(item)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(item.alias || item.name) + " ")]
                  )
                }),
                0
              ),
              _c("SearchPanel", {
                attrs: {
                  fromClass: "home-wrap",
                  cate: _vm.curCate,
                  isHome: true,
                  cityCode: _vm.cityCode,
                  placeholder: _vm.placeholder,
                },
                on: { search: _vm.search, searchAll: _vm.searchAll },
              }),
              _c(
                "div",
                {
                  staticClass:
                    "home-main home-wrap home-search-content incheck",
                },
                [
                  _c("span", { staticClass: "label text-c" }, [
                    _vm._v("大家在查"),
                  ]),
                  _vm._v("｜ "),
                  _vm._l(_vm.hotWords, function (item, index) {
                    return _c(
                      "span",
                      { key: "hot_" + index, staticClass: "hot-item" },
                      [_vm._v(" " + _vm._s(item) + " ")]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "home-data-capacity display-flex home-main align-items-center",
            },
            _vm._l(_vm.dataCapacity, function (item, index) {
              return _c(
                "div",
                {
                  key: "data_" + index,
                  staticClass:
                    "data-capacity-item display-flex justify-content-center align-items-center",
                },
                [
                  _c("img", {
                    staticClass: "item-icon",
                    attrs: { src: item.icon, alt: "" },
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "item-content display-flex flex-direction-column justify-content-space-between",
                    },
                    [
                      _c("span", { staticClass: "item-name" }, [
                        _vm._v(" " + _vm._s(item.name)),
                      ]),
                      _c("span", { staticClass: "item-num" }, [
                        _vm._v(" " + _vm._s(item.num) + " "),
                        _c("span", { staticClass: "item-unit" }, [
                          _vm._v(_vm._s(item.unit)),
                        ]),
                      ]),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
          _c("div", { staticClass: "business-wrap" }, [
            _c("div", { staticClass: "home-main" }, [
              _vm._m(0),
              _c("div", { staticClass: "business-content" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "business-item justify-content-space-between align-items-center",
                    class: _vm.sceneShow1 ? "height-max" : "display-flex",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "business-desc",
                        class: _vm.sceneShow1
                          ? "business-fadeOut"
                          : "business-fadeIn",
                      },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("商业营销评估"),
                        ]),
                        _c("div", { staticClass: "desc" }, [
                          _vm._v(
                            " 结合时空大数据、知识图谱、用户画像、深度学习等方面的能力，为客户提供精准营销解决方案，帮助客户构建多维客户洞察、精准位置投放、OMO全场景效果评估、精益化运营。 "
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "business-img",
                        class: _vm.sceneShow1 ? "scene-show1" : "",
                      },
                      [
                        _c("img", {
                          class: _vm.sceneShow1 ? "scene-show" : "",
                          attrs: {
                            src: require("../assets/images/home-business2.png"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.sceneShow1,
                            expression: "!sceneShow1",
                          },
                        ],
                        staticClass: "play-btn cur-pointer",
                        on: {
                          click: function ($event) {
                            return _vm.playShow1()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "play-icon" }),
                        _vm._v(" 播放 "),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.sceneShow1,
                            expression: "sceneShow1",
                          },
                        ],
                        staticClass: "back-btn cur-pointer",
                        on: {
                          click: function ($event) {
                            return _vm.playShow1()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "back-icon" }),
                        _vm._v(" 返回 "),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "business-item justify-content-space-between align-items-center",
                    class: _vm.sceneShow3 ? "height-max" : "display-flex",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "business-img-right",
                        class: _vm.sceneShow3 ? "scene-show2" : "",
                      },
                      [
                        _c("img", {
                          class: _vm.sceneShow3 ? "scene-show" : "",
                          attrs: {
                            src: require("../assets/images/home-portrait.png"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "business-desc business-desc-right",
                        class: _vm.sceneShow3
                          ? "business-fadeOut-right"
                          : "business-fadeIn-right",
                      },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("客流画像分析"),
                        ]),
                        _c("div", { staticClass: "desc" }, [
                          _vm._v(
                            " 通过一站式客户调研及时空洞察系统，结合海量城市人口用户画像数据，帮助客户用最少的投入全面便捷分析来客并洞察周边潜在客户、合理利用广告资源实现精准投放触达目标客户。 "
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.sceneShow3,
                            expression: "!sceneShow3",
                          },
                        ],
                        staticClass: "play-btn play-btn2 cur-pointer",
                        on: {
                          click: function ($event) {
                            return _vm.playShow3()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "play-icon" }),
                        _vm._v(" 播放 "),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.sceneShow3,
                            expression: "sceneShow3",
                          },
                        ],
                        staticClass: "back-btn cur-pointer",
                        on: {
                          click: function ($event) {
                            return _vm.playShow3()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "back-icon" }),
                        _vm._v(" 返回 "),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "business-item justify-content-space-between align-items-center",
                    class: _vm.sceneShow2 ? "height-max" : "display-flex",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "business-desc",
                        class: _vm.sceneShow2
                          ? "business-fadeOut"
                          : "business-fadeIn",
                      },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("地理位置分析"),
                        ]),
                        _c("div", { staticClass: "desc" }, [
                          _vm._v(
                            " 通过海量的城市商业地理数据结合可视化地图、时空智能、用户画像技术，帮助评估开店选址、推荐最优位置、合理划分区域、管理终端网点、订单智能分拣。 "
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "business-img localtion-img",
                        class: _vm.sceneShow2 ? "scene-show1" : "",
                      },
                      [
                        _c("img", {
                          staticClass: "map-bg",
                          attrs: {
                            src: require("../assets/images/home-map-bg.png"),
                            alt: "",
                          },
                        }),
                        _c("img", {
                          staticClass: "localtion",
                          class: _vm.sceneShow2 ? "scene-show" : "",
                          attrs: {
                            src: require("../assets/images/home-map-left.png"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.sceneShow2,
                            expression: "!sceneShow2",
                          },
                        ],
                        staticClass: "play-btn cur-pointer",
                        on: {
                          click: function ($event) {
                            return _vm.playShow2()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "play-icon" }),
                        _vm._v(" 播放 "),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.sceneShow2,
                            expression: "sceneShow2",
                          },
                        ],
                        staticClass: "back-btn cur-pointer",
                        on: {
                          click: function ($event) {
                            return _vm.playShow2()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "back-icon" }),
                        _vm._v(" 返回 "),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "business-item justify-content-space-between align-items-center",
                    class: _vm.sceneShow4 ? "height-max" : "display-flex",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "business-img-right",
                        class: _vm.sceneShow4 ? "scene-show2" : "",
                      },
                      [
                        _c("img", {
                          class: _vm.sceneShow4 ? "scene-show" : "",
                          attrs: {
                            src: require("../assets/images/home-city.png"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "business-desc business-desc-right",
                        class: _vm.sceneShow4
                          ? "business-fadeOut-right"
                          : "business-fadeIn-right",
                      },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("智慧城市建设"),
                        ]),
                        _c("div", { staticClass: "desc" }, [
                          _vm._v(
                            " 利用多源融合LBS及大数据技术将人口、商业、地理信息融汇到统一的城市时空大数据平台中，实现产业洞察、精准招商、投拓决策、区域分析、智能规划、智慧综治等功能。 "
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.sceneShow4,
                            expression: "!sceneShow4",
                          },
                        ],
                        staticClass: "play-btn play-btn2 cur-pointer",
                        on: {
                          click: function ($event) {
                            return _vm.playShow4()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "play-icon" }),
                        _vm._v(" 播放 "),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.sceneShow4,
                            expression: "sceneShow4",
                          },
                        ],
                        staticClass: "back-btn cur-pointer",
                        on: {
                          click: function ($event) {
                            return _vm.playShow4()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "back-icon" }),
                        _vm._v(" 返回 "),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._m(1),
          _c("div", { staticClass: "more-ability-wrap" }, [
            _c("div", { staticClass: "home-main" }, [
              _vm._m(2),
              _c(
                "div",
                { staticClass: "more-ability display-flex flex-wrap-wrap" },
                _vm._l(_vm.moreAbility, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: "more_" + index,
                      staticClass:
                        "more-ability-item display-flex justify-content-center",
                    },
                    [
                      _c("img", { attrs: { src: item.icon, alt: "" } }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "item-content display-flex flex-direction-column",
                        },
                        [
                          _c("span", { staticClass: "item-name" }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                          _c("span", { staticClass: "item-desc" }, [
                            _vm._v(_vm._s(item.desc)),
                          ]),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
          _c("div", { staticClass: "guide-wrap" }, [
            _c("div", { staticClass: "home-main" }, [
              _vm._m(3),
              _c(
                "span",
                {
                  staticClass:
                    "btn cur-pointer display-flex justify-content-center align-items-center text-c",
                  on: { click: _vm.toBrand },
                },
                [
                  _vm._v("开始免费使用 "),
                  _c("img", {
                    attrs: { src: "/images/home/right.png", alt: "" },
                  }),
                ]
              ),
            ]),
          ]),
          _c("Footer"),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "contact-panel middle-box" },
        [
          _c(
            "Tooltip",
            {
              staticClass: "contact-item phone",
              attrs: { placement: "left", theme: "light" },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("p", { staticClass: "tooltip-phone" }, [
                        _c("span", [_vm._v("咨询热线：")]),
                        _vm._v("010-86463634"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("div", { staticClass: "icon" }), _c("br"), _vm._v(" 咨询热线 ")]
          ),
          _c(
            "Tooltip",
            {
              staticClass: "qrcode-tooltip-wrap contact-item qrcode",
              attrs: { placement: "left-start", theme: "light" },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "tooltip-qrcode" }, [
                        _c("div", [
                          _c("div", { staticClass: "qrcode-icon" }, [
                            _c("img", {
                              staticClass: "center-middle-box",
                              attrs: {
                                src: require("@/assets/images/contact/gzh.png"),
                                alt: "",
                              },
                            }),
                          ]),
                          _vm._v(" 「 公众号 」 "),
                        ]),
                        _c("div", [
                          _c("div", { staticClass: "qrcode-icon" }, [
                            _c("img", {
                              staticClass: "center-middle-box",
                              attrs: {
                                src: "/images/home/qrcode.png",
                                alt: "",
                              },
                            }),
                          ]),
                          _vm._v(" 「 小程序 」 "),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("div", { staticClass: "icon" }), _c("br"), _vm._v(" 关注我们 ")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.toTopShow,
                  expression: "toTopShow",
                },
              ],
              staticClass: "contact-item to-top",
              on: { click: _vm.toTop },
            },
            [_c("div", { staticClass: "icon" }), _c("br"), _vm._v(" 返回顶部 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-wrap text-c" }, [
      _c("div", { staticClass: "primary-title" }, [
        _c("p", { staticClass: "en" }, [_vm._v("BUSINESS EMPOWERMENT")]),
        _c("span", { staticClass: "zh center-box" }, [_vm._v("商业赋能")]),
      ]),
      _c("p", { staticClass: "desc" }, [
        _vm._v(" 以更多选择满足业务的全面需求，为业务带来多维提升与创新突破 "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "home-main rolling-content" }, [
      _c("div", { staticClass: "title-wrap" }, [
        _c("div", { staticClass: "primary-title text-c" }, [
          _c("p", { staticClass: "en" }, [_vm._v("SERVING CUSTOMERS")]),
          _c("span", { staticClass: "zh center-box" }, [_vm._v("服务客户")]),
        ]),
        _c("p", { staticClass: "desc text-c" }, [
          _vm._v("众多行业，数百家企业与政府的共同选择"),
        ]),
      ]),
      _c("div", { staticClass: "rolling-wrap" }, [
        _c("img", {
          staticClass: "mask1",
          attrs: { src: require("../assets/images/mask1.png"), alt: "" },
        }),
        _c("div", { staticClass: "home-main rolling-img" }, [
          _c("img", {
            staticClass: "rolling",
            attrs: { src: require("../assets/images/customer.png"), alt: "" },
          }),
          _c("img", {
            staticClass: "rolling rolling2",
            attrs: { src: require("../assets/images/customer.png"), alt: "" },
          }),
        ]),
        _c("img", {
          staticClass: "mask2",
          attrs: { src: require("../assets/images/mask2.png"), alt: "" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-wrap" }, [
      _c("div", { staticClass: "primary-title text-c" }, [
        _c("p", { staticClass: "en" }, [_vm._v("MORE CAPABILITIES")]),
        _c("span", { staticClass: "zh center-box" }, [_vm._v("更多能力")]),
      ]),
      _c("p", { staticClass: "desc text-c" }, [
        _vm._v(
          " 整合线下数据+线上广告投放数据，将线下实体门店和地理位置洞察与线上商业化营销全域数据相融合 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "text" }, [
      _c("span", [_vm._v("探索商业数据")]),
      _vm._v(" "),
      _c("span", [_vm._v("发现商业机会")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }